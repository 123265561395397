import { Device } from "@awesome-cordova-plugins/device";
import { Capacitor } from "@capacitor/core";
import restActions from "src/actions/Rest";

export const saveDeviceData = async (deviceInfo, saveOnLocal = true) => {
  let localUserData = JSON.parse(localStorage.getItem('userDeviceData') ?? '{}');
  const userData = { ...localUserData, ...deviceInfo };

  saveOnLocal && localStorage.setItem("userDeviceData", JSON.stringify(userData));

  await restActions.POST("/devices", {
    ...userData,
    deviceId: Device.uuid,
    deviceType: Capacitor.getPlatform().toUpperCase(),
  });
};
