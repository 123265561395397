import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@capacitor/app";

// Here we handle deep linking
const AppUrlListener = () => {
  let history = useHistory();

  useEffect(() => {
    App.addListener("appUrlOpen", (event) => {
      // Ex.:- '/login?bin=87&...'
      const slug = event.url.split(".com").pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
