import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '@coreui/icons/css/all.css';
import './scss/style.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import { firebaseSetup } from './firebase-init';
// import { toastManager } from './reusable/toaster-manager';
import restActions from './actions/Rest';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@awesome-cordova-plugins/device';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications';
import { Idfa } from '@sparkfabrik/capacitor-plugin-idfa';
// import { useCookies } from 'react-cookie';
import { saveDeviceData } from './helper/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import AppUrlListener from './AppUrlListener';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import { getCurrentLocation } from './helper/geocoder';
import { StatusBar, Style } from '@capacitor/status-bar';

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  // const [cookies, setCookie] = useCookies(['deviceId']);
  // const [isTokenFound, setIsTokenFound] = useState(true);

  useEffect(() => {
    Capacitor.getPlatform() !== 'web' && SplashScreen.hide();
  }, []);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const initApp = async () => {
      Capacitor.getPlatform() === 'ios' && StatusBar.setStyle({ style: Style.Light });

      // Just for workaround for iOS 15 where subsequent permission request doesn't show
      // and this one was getting dismissed automatically when called in 'useGeocode.js'
      // So calling it here so we can wait and request all permissions one after other
      if (Capacitor.getPlatform() !== 'android') {
        // There's a conflict on Android when initially 'currentLocation' is called from multiple places
        // causing this call here to not return any promise, so rest of the code don't get executed, 
        // so we're not waiting in case of Android, but are, for iOS, as mentioned in above comment
        await getCurrentLocation();
  
        await sleep(1000);
      } else {
        getCurrentLocation();
      }

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      const result = await PushNotifications.requestPermissions();
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      }

      // Wait for some time as on iOS 15 multiple subsequent permission request don't show (Bug?)
      await sleep(1000);

      let advertisementID;
      try {
        // Get advertising id.
        const response = await Idfa.getAdvertisingInfo()

        if (response.isAdTrackingLimited === true) {
          console.error('Ads tracking not allowed by user.');
        }
        advertisementID = response.id;
        // setCookie('deviceId', response.id ? response.id : Device.uuid, { sameSite: 'none', secure: true, domain: process.env.REACT_APP_COOKIE_DOMAIN });
        // restActions.POST('/devices', {});
      } catch (err) {
        console.error('Idfa.getAdvertisingInfo', err)
      }

      PushNotifications.addListener('registration', (token) => {
        const deviceInfo = {
          // deviceId: Device.uuid,
          advertisementID,
          fcmToken: token.value
        };

        saveDeviceData(deviceInfo);
      });

      /* PushNotifications.addListener('registrationError', (error) => {
        // alert('Error on registration: ' + JSON.stringify(error));
      }); */

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          // alert('Push received: ' + JSON.stringify(notification));
          LocalNotifications.schedule({
            id: 1,
            title: notification.title,
            text: notification.body,
            smallIcon: 'res://notification_icon',
            color: '#00a8a1'
          });
        },
      );

      /* PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          // alert('Push action performed: ' + JSON.stringify(notification));
        },
      ); */
    }

    if (Capacitor.getPlatform() !== 'web') {
      initApp();
    } else {
      restActions.POST('/devices', {deviceType:'WEB'});
    }
  }, []);

  // useEffect(() => {
  //   firebaseSetup.init();
  //   firebaseSetup
  //     .getToken(setIsTokenFound)
  //     .then((token) => {
  //       console.log(token);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   firebaseSetup.onMessageListener().then((message) => {
  //     toastManager.info(message.notification.body, 5000, message.notification.title);
  //   });

  //   if (!isTokenFound) {
  //     toastManager.warn('Notification permission has been denied');
  //   }
  // }, [isTokenFound]);

  return (
    <BrowserRouter>
      {/* For Deep linking */}
      <AppUrlListener></AppUrlListener>
      <React.Suspense fallback={loading}>
        <Switch>
          {/* <Route exact path="/login/:bin/:weight/:qr/:material/:datetime" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/login/:transactionId" name="Login Page" render={props => <Login {...props}/>} /> */}
          <Route exact path='/login' name='Login Page' render={(props) => <Login {...props} />} />
          <Route exact path='/404' name='Page 404' render={(props) => <Page404 {...props} />} />
          <Route exact path='/500' name='Page 500' render={(props) => <Page500 {...props} />} />
          <Route path='/' name='Home' render={(props) => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
